import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { URLS } from "../components/Constants";
import "../style.css"; // Assicurati di creare e stilizzare questo file CSS
import moment from "moment"; // Assicurati di avere importato moment
import "moment/locale/it"; // Importa le locale necessarie
import "moment/locale/es"; // Ad esempio, per italiano e spagnolo
import "moment/locale/de"; //
import "moment/locale/fr"; //
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Modal from "react-modal";

function GestionePrenotazione() {
  const [prenotazioni, setPrenotazioni] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [showCanceled, setShowCanceled] = useState(false);
  const [modaleVisibile, setModaleVisibile] = useState(false);
  const [dettagliPrenotazione, setDettagliPrenotazione] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [cognome, setCognome] = useState("");
  const [numeroTelefono, setNumeroTelefono] = useState("");

  useEffect(() => {
    const browserLang = navigator.language || navigator.userLanguage;
    moment.locale(browserLang);

    fetch(URLS.getAllReservations)
      .then((response) => response.json())
      .then((data) =>
        setPrenotazioni(
          data.filter((p) => p.stato !== "annullato" && p.cancellato !== 1)
        )
      ) // Filtra le prenotazioni annullate e cancellate
      .catch((error) => console.error("Errore:", error));
  }, []);

  function handleSendToWhatsApp(numero_telefono) {
    const loginPageUrl = URLS.loginMenu + `?numero_telefono=${numero_telefono}`;
    const whatsappMessage = `Ciao, per favore compila il tuo menu al seguente link: ${loginPageUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${numero_telefono}&text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappUrl, "_blank");
  }

  async function handleDeleteReservation(id_prenotazione) {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler annullare questa prenotazione?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${URLS.deleteReservation}?id_prenotazione=${id_prenotazione}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        alert("Prenotazione annullata con successo!");
        setPrenotazioni(
          prenotazioni.map((p) =>
            p.id_prenotazione === id_prenotazione
              ? {
                  ...p,
                  cancellato: 1,
                  stato: "annullato",
                  data_cancellazione: new Date().toISOString(),
                }
              : p
          )
        );
        setModaleVisibile(false);
      } else {
        alert("Errore durante l'annullamento della prenotazione.");
      }
    } catch (error) {
      console.error("Errore:", error);
      alert("Errore durante l'annullamento della prenotazione.");
    }
  }

  const handleSearch = async () => {
    let url = `${URLS.cercaPrenotazione}?query=${searchQuery}`;
    if (checkInDate && checkOutDate) {
      url += `&checkin=${checkInDate.toISOString()}&checkout=${checkOutDate.toISOString()}`;
    }

    const response = await fetch(url);
    const data = await response.json();
    if (response.ok) {
      setResults(
        data.filter((p) =>
          showCanceled
            ? p.stato === "annullato" || p.cancellato === 1
            : p.stato !== "annullato" && p.cancellato !== 1
        )
      );
    } else {
      alert(data.error || "Errore durante la ricerca delle prenotazioni.");
    }
  };

  const openModal = (prenotazione) => {
    setDettagliPrenotazione(prenotazione);
    setCognome(prenotazione.cognome);
    setNumeroTelefono(prenotazione.numero_telefono);
    setModaleVisibile(true);
  };

  const closeModal = () => {
    setModaleVisibile(false);
    setDettagliPrenotazione(null);
    setEditMode(false);
  };

  const handleAddGuest = async () => {
    const additionalGuests = prompt("Quanti ospiti vuoi aggiungere?", "0");
    if (additionalGuests) {
      const newGuestCount = parseInt(additionalGuests);
      if (!isNaN(newGuestCount) && newGuestCount > 0) {
        try {
          const response = await fetch(`${URLS.addGuest}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id_prenotazione: dettagliPrenotazione.id_prenotazione,
              additionalGuests: newGuestCount,
            }),
          });
          if (response.ok) {
            const updatedPrenotazione = {
              ...dettagliPrenotazione,
              ospiti: dettagliPrenotazione.ospiti + newGuestCount,
            };
            setDettagliPrenotazione(updatedPrenotazione);
            setPrenotazioni(
              prenotazioni.map((p) =>
                p.id_prenotazione === updatedPrenotazione.id_prenotazione
                  ? updatedPrenotazione
                  : p
              )
            );
            alert("Ospiti aggiunti con successo!");
          } else {
            alert("Errore durante l'aggiunta degli ospiti.");
          }
        } catch (error) {
          console.error("Errore:", error);
          alert("Errore durante l'aggiunta degli ospiti.");
        }
      }
    }
  };

  const handleUpdateUser = async () => {
    try {
      const response = await fetch(`${URLS.updateUser}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_utente: dettagliPrenotazione.id_utente,
          cognome,
          numero_telefono: numeroTelefono,
        }),
      });

      if (response.ok) {
        const updatedPrenotazione = {
          ...dettagliPrenotazione,
          cognome,
          numero_telefono: numeroTelefono,
        };
        setDettagliPrenotazione(updatedPrenotazione);
        setPrenotazioni(
          prenotazioni.map((p) =>
            p.id_prenotazione === updatedPrenotazione.id_prenotazione
              ? updatedPrenotazione
              : p
          )
        );
        alert("Dati utente aggiornati con successo!");
        setEditMode(false);
      } else {
        alert("Errore durante l'aggiornamento dei dati utente.");
      }
    } catch (error) {
      console.error("Errore:", error);
      alert("Errore durante l'aggiornamento dei dati utente.");
    }
  };

  return (
    <div className="col-70 main-content">
      <div className="gestione-prenotazione">
        <h1>Gestione Prenotazioni</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Cerca per numero di telefono o cognome"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="date-picker">
            <label>Check-in: </label>
            <DatePicker
              selected={checkInDate}
              onChange={(date) => setCheckInDate(date)}
              dateFormat="yyyy/MM/dd"
            />
            <label>Check-out: </label>
            <DatePicker
              selected={checkOutDate}
              onChange={(date) => setCheckOutDate(date)}
              dateFormat="yyyy/MM/dd"
            />
          </div>
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={showCanceled}
                onChange={() => setShowCanceled(!showCanceled)}
              />
              Mostra prenotazioni cancellate
            </label>
          </div>
          <button className="btn-piatti" onClick={handleSearch}>
            Cerca
          </button>
        </div>
        <div className="results">
          <h2>Risultati della ricerca:</h2>
          <table>
            <thead>
              <tr>
                <th>ID Prenotazione</th>
                <th>Cognome</th>
                <th>Telefono</th>
                <th>Pensione</th>
                <th>Servizio</th>
                <th>ID Tavolo</th>
                <th>Data e Ora</th>
                <th>Stato</th>
                <th>Menu compilato</th>
                <th>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {searchQuery || checkInDate || checkOutDate
                ? results.map((prenotazione) => (
                    <tr key={prenotazione.id_prenotazione}>
                      <td>{prenotazione.id_prenotazione}</td>
                      <td
                        onClick={() => openModal(prenotazione)}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {prenotazione.cognome}
                      </td>
                      <td>{prenotazione.numero_telefono}</td>
                      <td>{prenotazione.pensione}</td>
                      <td>{prenotazione.quando}</td>
                      <td>{prenotazione.id_tavolo}</td>
                      <td>{moment(prenotazione.data_ora).format("LL")}</td>
                      <td
                        className={
                          prenotazione.stato === "annullato" ? "annullato" : ""
                        }
                      >
                        {prenotazione.stato}
                      </td>
                      <td>{prenotazione.compilato ? "Sì" : "No"}</td>
                      <td>
                        <button
                          className="button-wa"
                          onClick={() =>
                            handleSendToWhatsApp(prenotazione.numero_telefono)
                          }
                        >
                          <FontAwesomeIcon icon={faWhatsapp} /> Invia Link Menu
                        </button>
                        <button
                          className="button-cancel"
                          onClick={() =>
                            handleDeleteReservation(
                              prenotazione.id_prenotazione
                            )
                          }
                        >
                          Annulla prenotazione
                        </button>
                      </td>
                    </tr>
                  ))
                : prenotazioni.map((prenotazione) => (
                    <tr key={prenotazione.id_prenotazione}>
                      <td>{prenotazione.id_prenotazione}</td>
                      <td
                        onClick={() => openModal(prenotazione)}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {prenotazione.cognome}
                      </td>
                      <td>{prenotazione.numero_telefono}</td>
                      <td>{prenotazione.pensione}</td>
                      <td>{prenotazione.quando}</td>
                      <td>{prenotazione.id_tavolo}</td>
                      <td>{moment(prenotazione.data_ora).format("LL")}</td>
                      <td>{prenotazione.stato}</td>
                      <td>{prenotazione.compilato ? "Sì" : "No"}</td>
                      <td>
                        <button
                          className="button-wa"
                          onClick={() =>
                            handleSendToWhatsApp(prenotazione.numero_telefono)
                          }
                        >
                          <FontAwesomeIcon icon={faWhatsapp} /> Invia Link Menu
                        </button>
                        <button
                          className="button-cancel"
                          onClick={() =>
                            handleDeleteReservation(
                              prenotazione.id_prenotazione
                            )
                          }
                        >
                          Annulla prenotazione
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      {modaleVisibile && (
        <Modal
          isOpen={modaleVisibile}
          onRequestClose={closeModal}
          contentLabel="Dettagli Prenotazione"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>Dettagli Prenotazione</h2>
          {dettagliPrenotazione && (
            <div>
              <p>
                <strong>ID Prenotazione:</strong>{" "}
                {dettagliPrenotazione.id_prenotazione}
              </p>
              {editMode ? (
                <>
                  <div className="form-group">
                    <label htmlFor="cognome">Cognome:</label>
                    <input
                      type="text"
                      id="cognome"
                      value={cognome}
                      onChange={(e) => setCognome(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="numeroTelefono">Telefono:</label>
                    <input
                      type="text"
                      id="numeroTelefono"
                      value={numeroTelefono}
                      onChange={(e) => setNumeroTelefono(e.target.value)}
                    />
                  </div>
                  <button className="btn-piatti" onClick={handleUpdateUser}>
                    Salva Modifiche
                  </button>
                  <button
                    className="btn-piatti"
                    onClick={() => setEditMode(false)}
                  >
                    Annulla
                  </button>
                </>
              ) : (
                <>
                  <p>
                    <strong>Cognome:</strong>{" "}
                    <span
                      onClick={() => setEditMode(true)}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {dettagliPrenotazione.cognome}
                    </span>
                  </p>
                  <p>
                    <strong>Telefono:</strong>{" "}
                    {dettagliPrenotazione.numero_telefono}
                  </p>
                  <p>
                    <strong>Servizio:</strong> {dettagliPrenotazione.quando}
                  </p>
                  <p>
                    <strong>Pensione:</strong> {dettagliPrenotazione.pensione}
                  </p>
                  <p>
                    <strong>Ospiti:</strong> {dettagliPrenotazione.ospiti}
                  </p>
                  <p>
                    <strong>ID Tavolo:</strong> {dettagliPrenotazione.id_tavolo}
                  </p>
                  <p>
                    <strong>Data e Ora:</strong>{" "}
                    {moment(dettagliPrenotazione.data_ora).format("LLL")}
                  </p>
                  <p>
                    <strong>Stato:</strong> {dettagliPrenotazione.stato}
                  </p>
                  <p>
                    <strong>Menu compilato:</strong>{" "}
                    {dettagliPrenotazione.compilato ? "Sì" : "No"}
                  </p>
                </>
              )}
            </div>
          )}
          <button className="btn-piatti" onClick={closeModal}>
            Chiudi
          </button>
          <button className="btn-piatti-2" onClick={handleAddGuest}>
            Aggiungi Ospite
          </button>
          <button
            className="btn-piatti btn-delete"
            onClick={() =>
              handleDeleteReservation(dettagliPrenotazione.id_prenotazione)
            }
          >
            Cancella Prenotazione
          </button>
        </Modal>
      )}
    </div>
  );
}

export default GestionePrenotazione;
