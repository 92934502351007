const APIURL = "https://simply-menu-backend.vercel.app/api/";
const DOMAINURL = "https://simplymenu.website/";

export const URLS = {
  APIURL,
  getAllDishes: APIURL + "dishes", // Modificato come richiesto
  listaPiatti: APIURL + "active-dishes",
  statistica: APIURL + "statistics", // Modificato come richiesto
  getAllReservations: APIURL + "all-reservations", // Modificato come richiesto
  getDailyReservationsWithOrders: APIURL + "daily-reservations-orders", // Modificato come richiesto
  getPrenotazioniGiornaliere: APIURL + "daily-reservations", // Modificato come richiesto
  getDishes: APIURL + "dishes", // Modificato come richiesto
  getAllMenus: APIURL + "menus", // Modificato come richiesto
  getMenuByDateAndService: APIURL + "menu-date-service", // Modificato come richiesto
  aggiungiPiatto: APIURL + "add-dish",
  getMenuByReservation: APIURL + "menu-by-reservation", // Modificato come richiesto
  gestisciMenu: APIURL + "manage-menu",
  cancellaPiatto: APIURL + "delete-dish",
  addCustomer: APIURL + "add-customer",
  makeReservation: APIURL + "make-reservation",
  prenotazioni: APIURL + "last-five-reservations",
  getChef: APIURL + "chef-grid",
  getIdMenuPerDataEServizio: APIURL + "get-menu-by-date-and-service",
  aggiungiUtente: APIURL + "add-user",
  inserisciPrenotazione: APIURL + "add-reservation",
  getAllMenu: APIURL + "get-all-menus",
  cercaUtente: APIURL + "cerca-utente",
  getMenuGiornaliero: APIURL + "get-menu-giornaliero",
  assegnaMenu: APIURL + "assegna-menu",
  cercaPrenotazioniPerTelefono: APIURL + "cerca-prenotazioni-per-telefono",
  loginMenu: DOMAINURL + "login-menu",
  getReservationGuest: APIURL + "get-reservation-guest",
  getPiattiMenu: APIURL + "get-piatti-menu",
  pushMessaggioPersonalizzato: APIURL + "push-messaggio-custom",
  compilaOrdine: APIURL + "compila-ordine",
  aggiornaPiatto: APIURL + "update-piatto",
  cercaPrenotazione: APIURL + "cerca-prenotazione-info",
  getGridMaitre: APIURL + "maitre-grid",
  getTavoliLiberi: APIURL + "cerca-tavoli-liberi",
  assignTable: APIURL + "assegna-tavoli",
  updateMenu: APIURL + "update-menu",
  inserisciTavolo: APIURL + "inserisci-tavolo",
  getTavoli: APIURL + "get-tavoli",
  visualizzaTuoMenu: APIURL + "recupera-ordine-telefono",
  deleteReservation: APIURL + "delete-reservation",
  deleteMenu: APIURL + "delete-menu",
  getDatiCalcolati: APIURL + "calcola-pax",
  addGuest: APIURL + "add-guest",
  deleteMenuAssignment: APIURL + "delete-menu-assign",
  modificaTavolo: APIURL + "update-table",
  cancellaTavolo: APIURL + "delete-table",
  updateUser: APIURL + "update-user",
};
