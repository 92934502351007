import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../components/Constants"; // Corretto il percorso dell'importazione
import "./LoginForm.css"; // Importa il file CSS

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Usa useNavigate di react-router-dom

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(URLS.APIURL + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        navigate("/dashboard"); // Redirect to the dashboard or any other page
      } else {
        setError("Invalid email or password");
      }
    } catch (error) {
      console.error("Failed to login", error);
      setError("Invalid email or password");
    }
  };

  return (
    <div className="login-form-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
        <a href="/recupera-password">Recupera password</a>
      </form>
    </div>
  );
}
