import React from "react";

function Modal({ onClose, title, children }) {
  return (
    <div className="modal">
      <header>{title}</header>
      {children}
      <button onClick={onClose}>Chiudi</button>
    </div>
  );
}

export default Modal;
