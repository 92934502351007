import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUtensils,
  faInfo,
  faCalendarAlt,
  faBook,
  faUserPlus,
  faSignOutAlt,
  faClipboardList,
  faChartLine,
  faCog,
  faTable,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { URLS } from "../components/Constants"; // Assicurati che il percorso sia corretto

function Sidebar() {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleLogout = async () => {
    try {
      const response = await fetch(URLS.APIURL + "logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        navigate("/login"); // Reindirizza alla pagina di login dopo il logout
      } else {
        console.error("Failed to logout", response.statusText);
      }
    } catch (error) {
      console.error("Failed to logout", error);
    }
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`navbar ${isExpanded ? "expanded" : "collapsed"}`}>
      <div className="toggle-btn" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isExpanded ? faChevronLeft : faChevronRight} />
      </div>
      <h2>{isExpanded && "Menu"}</h2>
      <ul>
        <li>
          <NavLink to="/dashboard" activeClassName="active">
            <FontAwesomeIcon icon={faTachometerAlt} />{" "}
            {isExpanded && "Dashboard"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/inserisci-prenotazione" activeClassName="active">
            <FontAwesomeIcon icon={faUserPlus} />{" "}
            {isExpanded && "Inserisci Prenotazione"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/gestione-prenotazione" activeClassName="active">
            <FontAwesomeIcon icon={faBook} />{" "}
            {isExpanded && "Cerca Prenotazione"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/gestione-piatto" activeClassName="active">
            <FontAwesomeIcon icon={faUtensils} />{" "}
            {isExpanded && "Gestione Piatti"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/visualizza-menu" activeClassName="active">
            <FontAwesomeIcon icon={faClipboardList} />{" "}
            {isExpanded && "Visualizza Menu"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/crea-menu" activeClassName="active">
            <FontAwesomeIcon icon={faCalendarAlt} /> {isExpanded && "Crea Menu"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/calendario-menu" activeClassName="active">
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            {isExpanded && "Calendario Menu"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/tavoli" activeClassName="active">
            <FontAwesomeIcon icon={faTable} /> {isExpanded && "Gestisci Tavoli"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/griglia-chef" activeClassName="active">
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            {isExpanded && "Griglia Chef"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/griglia-maitre" activeClassName="active">
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            {isExpanded && "Griglia Maitre"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/report" activeClassName="active">
            <FontAwesomeIcon icon={faChartLine} /> {isExpanded && "Report"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/impostazioni" activeClassName="active">
            <FontAwesomeIcon icon={faCog} /> {isExpanded && "Impostazioni"}
          </NavLink>
        </li>
        <li>
          <button onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} /> {isExpanded && "Logout"}
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
