import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/it"; // Importa la localizzazione italiana
import "react-big-calendar/lib/css/react-big-calendar.css";
import { URLS } from "../components/Constants";
import MenuAssignmentModal from "../components/MenuAssignmentModal";
import "../style.css"; // Assicurati di avere uno stile per il calendario personalizzato

moment.locale("it"); // Imposta moment per utilizzare la lingua italiana
const localizer = momentLocalizer(moment);

function CalendarioMenu() {
  const [events, setEvents] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().toDate());
  const [view, setView] = useState("week"); // Imposta la vista settimanale come predefinita

  useEffect(() => {
    fetchMenusForCurrentWeek(currentDate);
    fetchMenuList();
  }, [currentDate]);

  const fetchMenusForCurrentWeek = async (date) => {
    try {
      const startOfWeek = moment(date).startOf("isoWeek").format("YYYY-MM-DD");
      const endOfWeek = moment(date).endOf("isoWeek").format("YYYY-MM-DD");
      const response = await fetch(
        `${URLS.getMenuGiornaliero}?data_inizio=${startOfWeek}&data_fine=${endOfWeek}`
      );
      const menus = await response.json();
      const formattedEvents = menus
        .map((menu) => {
          const startHour = menu.tipo_servizio === "pranzo" ? 12 : 19;
          const endHour = menu.tipo_servizio === "pranzo" ? 14 : 21;
          if (!menu.nome_menu) {
            console.error("Menu senza nome trovato:", menu);
            return null;
          }
          return {
            title: `${menu.nome_menu} (${menu.tipo_servizio})`,
            start: new Date(
              moment(menu.data).set("hour", startHour).toISOString()
            ),
            end: new Date(moment(menu.data).set("hour", endHour).toISOString()),
            allDay: false,
            resource: menu,
            className:
              menu.tipo_servizio === "pranzo" ? "event-pranzo" : "event-cena",
          };
        })
        .filter((event) => event !== null);
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Errore nel caricamento dei menu:", error);
    }
  };

  const fetchMenuList = async () => {
    try {
      const response = await fetch(URLS.getAllMenus);
      const menus = await response.json();
      setMenuList(menus);
    } catch (error) {
      console.error("Errore nel caricamento della lista dei menu:", error);
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedMenu(event.resource);
    setIsModalOpen(true);
  };

  const handleOpenModalForNewMenu = () => {
    setSelectedMenu(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMenu(null);
  };

  const handleSaveMenu = async (id_menu, dayFrom, dayTo, tipo_servizio) => {
    try {
      const response = await fetch(URLS.assegnaMenu, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_menu, dayFrom, dayTo, tipo_servizio }),
      });

      if (response.ok) {
        fetchMenusForCurrentWeek(currentDate);
        alert("Menu aggiornato correttamente!");
      } else {
        const result = await response.json();
        throw new Error(
          result.message || "Errore durante l'aggiornamento del menu"
        );
      }
    } catch (error) {
      console.error("Errore durante l'aggiornamento del menu:", error.message);
      alert("Errore durante l'aggiornamento del menu: " + error.message);
    }
  };

  const handleDeleteMenuAssignment = async (id_menu, data) => {
    try {
      const response = await fetch(URLS.deleteMenuAssignment, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_menu, data }),
      });

      if (response.ok) {
        fetchMenusForCurrentWeek(currentDate);
        alert("Assegnazione cancellata correttamente!");
      } else {
        const result = await response.json();
        throw new Error(
          result.message || "Errore durante la cancellazione dell'assegnazione"
        );
      }
    } catch (error) {
      console.error(
        "Errore durante la cancellazione dell'assegnazione:",
        error.message
      );
      alert(
        "Errore durante la cancellazione dell'assegnazione: " + error.message
      );
    }
  };

  const handleNavigate = (date) => {
    setCurrentDate(date);
  };

  const eventPropGetter = (event) => {
    const backgroundColor =
      event.resource.tipo_servizio === "pranzo" ? "#99d8c9" : "#075d49";
    const className =
      event.resource.tipo_servizio === "pranzo" ? "event-pranzo" : "event-cena";
    return {
      className,
      style: {
        backgroundColor,
        borderColor: backgroundColor,
        color: "white",
      },
    };
  };

  return (
    <div className="calendario-menu main-content">
      <button className="btn-piatti" onClick={handleOpenModalForNewMenu}>
        Imposta Menu
      </button>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, width: "100%" }}
        onSelectEvent={handleSelectEvent}
        onNavigate={handleNavigate}
        date={currentDate}
        view={view} // Imposta la vista attiva
        onView={setView} // Permetti di cambiare vista
        views={["week", "day", "agenda"]} // Rimuovi la vista mensile
        eventPropGetter={eventPropGetter} // Aggiungi la funzione di gestione delle proprietà degli eventi
        messages={{
          next: "Prossimo",
          previous: "Precedente",
          today: "Oggi",
          week: "Settimana",
          day: "Giorno",
          agenda: "Agenda",
          date: "Data",
          time: "Ora",
          event: "Evento",
          noEventsInRange: "Non ci sono eventi in questo intervallo.",
          showMore: (total) => `+ Mostra altri ${total}`,
        }}
      />
      <MenuAssignmentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveMenu}
        onDelete={handleDeleteMenuAssignment}
        menu={selectedMenu}
        menuList={menuList}
      />
    </div>
  );
}

export default CalendarioMenu;
