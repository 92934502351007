import React, { useState, useEffect } from "react";
import { URLS } from "../components/Constants";

function InserisciTavolo() {
  const [numeroTavolo, setNumeroTavolo] = useState("");
  const [capacita, setCapacita] = useState("");
  const [message, setMessage] = useState("");
  const [tavoli, setTavoli] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingTavolo, setEditingTavolo] = useState(null);

  useEffect(() => {
    const fetchTavoli = async () => {
      const tavoliEsistenti = await getTavoli();
      setTavoli(tavoliEsistenti);
    };

    fetchTavoli();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nuovoTavolo = {
      id_ristorante: 1,
      numero_tavolo: numeroTavolo,
      capacita: capacita,
    };

    try {
      const response = await fetch(`${URLS.inserisciTavolo}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nuovoTavolo),
      });

      if (response.ok) {
        setMessage("Tavolo inserito con successo!");
        setNumeroTavolo("");
        setCapacita("");
        setShowModal(false);
        const tavoliEsistenti = await getTavoli();
        setTavoli(tavoliEsistenti);
      } else {
        const errorData = await response.json();
        setMessage(`Errore: ${errorData.error}`);
      }
    } catch (error) {
      setMessage(`Errore di rete: ${error.message}`);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const tavoloModificato = {
      id_tavolo: editingTavolo.id_tavolo,
      numero_tavolo: numeroTavolo,
      capacita: capacita,
    };

    try {
      const response = await fetch(`${URLS.modificaTavolo}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tavoloModificato),
      });

      if (response.ok) {
        setMessage("Tavolo modificato con successo!");
        setEditingTavolo(null);
        setNumeroTavolo("");
        setCapacita("");
        setShowModal(false);
        const tavoliEsistenti = await getTavoli();
        setTavoli(tavoliEsistenti);
      } else {
        const errorData = await response.json();
        setMessage(`Errore: ${errorData.error}`);
      }
    } catch (error) {
      setMessage(`Errore di rete: ${error.message}`);
    }
  };

  const handleDelete = async (id_tavolo) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler cancellare questo tavolo?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${URLS.cancellaTavolo}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_tavolo }),
      });

      if (response.ok) {
        setMessage("Tavolo cancellato con successo!");
        const tavoliEsistenti = await getTavoli();
        setTavoli(tavoliEsistenti);
      } else {
        const errorData = await response.json();
        setMessage(`Errore: ${errorData.error}`);
      }
    } catch (error) {
      setMessage(`Errore di rete: ${error.message}`);
    }
  };

  const handleEditClick = (tavolo) => {
    setEditingTavolo(tavolo);
    setNumeroTavolo(tavolo.numero_tavolo);
    setCapacita(tavolo.capacita);
    setShowModal(true);
  };

  const getTavoli = async () => {
    try {
      const response = await fetch(`${URLS.getTavoli}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Errore nel recupero dei tavoli");
      }

      const data = await response.json();
      return data.tavoli;
    } catch (error) {
      console.error("Errore di rete:", error);
      return [];
    }
  };

  return (
    <div className="main-content">
      <h1>Tavoli del Ristorante</h1>
      <button className="btn" onClick={() => setShowModal(true)}>
        Aggiungi Tavolo
      </button>
      <div className="tavoli-container">
        {tavoli.map((tavolo) => (
          <div key={tavolo.id_tavolo} className="tavolo">
            <p>Tavolo {tavolo.numero_tavolo}</p>
            <p>Capacità: {tavolo.capacita}</p>
            <button className="btn" onClick={() => handleEditClick(tavolo)}>
              Modifica
            </button>
            <button
              className="btn btn-delete"
              onClick={() => handleDelete(tavolo.id_tavolo)}
            >
              Cancella
            </button>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="modal-background">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h2>
              {editingTavolo ? "Modifica Tavolo" : "Inserisci Nuovo Tavolo"}
            </h2>
            <form onSubmit={editingTavolo ? handleUpdate : handleSubmit}>
              <div className="form-group">
                <label>Numero Tavolo:</label>
                <input
                  type="text"
                  value={numeroTavolo}
                  onChange={(e) => setNumeroTavolo(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Capacità:</label>
                <input
                  type="number"
                  value={capacita}
                  onChange={(e) => setCapacita(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="modal-button">
                {editingTavolo ? "Salva Modifiche" : "Inserisci Tavolo"}
              </button>
              {editingTavolo && (
                <button
                  type="button"
                  className="modal-button btn-delete"
                  onClick={() => handleDelete(editingTavolo.id_tavolo)}
                >
                  Cancella Tavolo
                </button>
              )}
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>
      )}
    </div>
  );
}

export default InserisciTavolo;
