import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import { URLS } from "../components/Constants";

const allergeniList = [
  { id: 1, nome: "Glutine", icona: "🌾" },
  { id: 2, nome: "Latticini", icona: "🥛" },
  { id: 3, nome: "Uova", icona: "🥚" },
  { id: 4, nome: "Arachidi", icona: "🥜" },
  { id: 5, nome: "Pesce", icona: "🐟" },
];

function CompilaMenu() {
  const { id_prenotazione } = useParams();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const data_ora = queryParams.data_ora;

  const [piatti, setPiatti] = useState([]);
  const [numeroOspiti, setNumeroOspiti] = useState(0);
  const [quantitaPiatti, setQuantitaPiatti] = useState({});
  const [richiesteSpeciali, setRichiesteSpeciali] = useState({});
  const [extra, setExtra] = useState(0);
  const [extraCount, setExtraCount] = useState(0);

  useEffect(() => {
    const data_inizio = new Date(data_ora);
    const data_fine = new Date(data_inizio);
    data_fine.setDate(data_fine.getDate() + 7);
    fetchMenuGiornaliero(
      data_inizio.toISOString().split("T")[0],
      data_fine.toISOString().split("T")[0]
    );
    fetchNumeroOspiti(id_prenotazione);
  }, [id_prenotazione, data_ora]);

  useEffect(() => {
    setQuantitaPiatti(
      piatti.reduce((acc, piatto) => ({ ...acc, [piatto.id_piatto]: 0 }), {})
    );
    setRichiesteSpeciali(
      piatti.reduce((acc, piatto) => ({ ...acc, [piatto.id_piatto]: "" }), {})
    );
  }, [piatti]);

  async function fetchMenuGiornaliero(data_inizio, data_fine) {
    const response = await fetch(
      `${URLS.getMenuGiornaliero}?data_inizio=${data_inizio}&data_fine=${data_fine}`
    );
    const dataMenu = await response.json();
    if (dataMenu && Array.isArray(dataMenu)) {
      const idMenu = dataMenu[0]?.id_menu;
      fetchPiatti(idMenu);
    }
  }

  async function fetchNumeroOspiti(id) {
    const response = await fetch(
      `${URLS.getReservationGuest}?id_prenotazione=${id}`
    );
    const data = await response.json();
    setNumeroOspiti(data.ospiti);
  }

  async function fetchPiatti(idMenu) {
    const response = await fetch(`${URLS.getPiattiMenu}?id_menu=${idMenu}`);
    const piattiMenu = await response.json();
    setPiatti(
      piattiMenu.flat().map((piatto) => ({
        ...piatto,
        allergeni: piatto.allergeni ? JSON.parse(piatto.allergeni) : [],
      }))
    );
    console.log("Piatti fetched:", piattiMenu);
  }

  function handleQuantityChange(piattoId, increment) {
    setQuantitaPiatti((prev) => {
      const newCount = Math.max(0, prev[piattoId] + increment);
      return {
        ...prev,
        [piattoId]: newCount,
      };
    });
  }

  function handleRichiesteSpecialiChange(piattoId, value) {
    setRichiesteSpeciali((prev) => ({
      ...prev,
      [piattoId]: value,
    }));
  }

  function totalSelections() {
    return Object.values(quantitaPiatti).reduce((sum, num) => sum + num, 0);
  }

  function getPrice(piattoId) {
    const piatto = piatti.find(
      (piatto) => piatto.id_piatto === parseInt(piattoId)
    );
    const prezzo = piatto ? piatto.prezzo : 0;
    console.log(
      `getPrice -> Piatto ID: ${piattoId}, Prezzo: ${prezzo}, Piatto:`,
      piatto
    );
    return prezzo;
  }

  function calculateExtra() {
    const freeLimit = {
      Primo: numeroOspiti * 1,
      Secondo: numeroOspiti * 1,
      Dolce: numeroOspiti,
    };

    let extraCount = 0;
    let extraCost = 0;

    const piattiOrdinati = Object.entries(quantitaPiatti).map(
      ([id, quantita]) => ({
        id_piatto: id,
        quantita: quantita,
        portata:
          piatti.find((p) => p.id_piatto === parseInt(id))?.portata || "",
      })
    );

    const categoriaCount = {
      Primo: 0,
      Secondo: 0,
      Dolce: 0,
    };

    piattiOrdinati.forEach((ordine) => {
      if (ordine.quantita > 0) {
        categoriaCount[ordine.portata] += ordine.quantita;
      }
    });

    piattiOrdinati.forEach((ordine) => {
      const categoria = ordine.portata;
      const limite = freeLimit[categoria];
      const count = categoriaCount[categoria];

      if (count > limite) {
        const extraForPiatto = Math.min(count - limite, ordine.quantita);
        extraCount += extraForPiatto;
        const prezzo = getPrice(ordine.id_piatto);
        console.log(
          `calculateExtra -> Piatto ID: ${ordine.id_piatto}, Prezzo: ${prezzo}, Quantità extra: ${extraForPiatto}`
        );
        extraCost += prezzo * extraForPiatto;
        categoriaCount[categoria] -= extraForPiatto;
      }
    });

    console.log(
      `calculateExtra -> Costo totale extra: ${extraCost}, Contatore extra: ${extraCount}`
    );
    setExtra(extraCost);
    setExtraCount(extraCount);
    return extraCost;
  }

  useEffect(() => {
    calculateExtra();
  }, [quantitaPiatti]);

  async function handleSubmit(e) {
    e.preventDefault();
    const freeLimit = {
      Primo: numeroOspiti * 1,
      Secondo: numeroOspiti * 1,
      Dolce: numeroOspiti,
    };

    const categoriaCount = {
      Primo: 0,
      Secondo: 0,
      Dolce: 0,
    };

    const ordini = Object.keys(quantitaPiatti)
      .filter((piattoId) => quantitaPiatti[piattoId] > 0)
      .map((piattoId) => {
        const quantita = quantitaPiatti[piattoId];
        const portata = piatti.find(
          (p) => p.id_piatto === parseInt(piattoId)
        ).portata;
        categoriaCount[portata] += quantita;
        const limite = freeLimit[portata];
        const extraQuantita =
          categoriaCount[portata] > limite
            ? categoriaCount[portata] - limite
            : 0;

        return {
          id_piatto: piattoId,
          quantita,
          richieste_speciali: richiesteSpeciali[piattoId],
          extra: extraQuantita * getPrice(piattoId),
        };
      });

    try {
      const response = await fetch(URLS.compilaOrdine, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_prenotazione,
          ordini,
          extra,
        }),
      });

      if (response.ok) {
        alert("Menu compilato con successo!");

        // Fetch the phone number for the reservation
        const res = await fetch(
          `${URLS.getReservationGuest}?id_prenotazione=${id_prenotazione}`
        );
        const data = await res.json();
        const numeroTelefono = data.numero_telefono;

        // Redirect to login page with the phone number
        window.location.href = `/login-menu?numero_telefono=${numeroTelefono}`;
      } else {
        const result = await response.json();
        alert("Errore: " + result.error);
      }
    } catch (error) {
      console.error("Errore durante l'invio del menu:", error);
      alert("Errore durante l'invio del menu.");
    }
  }

  return (
    <div className="compilation-form">
      <h1>Compilazione Menu</h1>
      {/* Legenda degli allergeni */}
      <div className="allergeni-leggenda">
        <h3>Legenda Allergeni</h3>
        <ul>
          {allergeniList.map((allergene) => (
            <li key={allergene.id}>
              {allergene.icona} {allergene.nome}
            </li>
          ))}
        </ul>
      </div>
      <form onSubmit={handleSubmit}>
        {["Primo", "Secondo", "Dolce"].map((categoria) => (
          <div key={categoria}>
            <h3>{categoria}</h3>
            {piatti
              .filter((piatto) => piatto.portata === categoria)
              .map((piatto) => (
                <div className="selezione-menu" key={piatto.id_piatto}>
                  <div className="quantita-control">
                    <button
                      type="button"
                      onClick={() => handleQuantityChange(piatto.id_piatto, -1)}
                    >
                      -
                    </button>
                    <span>{quantitaPiatti[piatto.id_piatto]}</span>
                    <button
                      type="button"
                      onClick={() => handleQuantityChange(piatto.id_piatto, 1)}
                    >
                      +
                    </button>
                  </div>
                  <span className="nome-piatto">{piatto.nome}</span>
                  <span className="prezzo">{piatto.prezzo}€</span>
                  <div className="allergeni">
                    {piatto.allergeni &&
                      piatto.allergeni.map((allergeneId) => {
                        const allergene = allergeniList.find(
                          (a) => a.id === allergeneId
                        );
                        return (
                          <span key={allergeneId} className="allergene">
                            {allergene.icona}
                          </span>
                        );
                      })}
                  </div>
                  <textarea
                    placeholder="Richieste speciali"
                    value={richiesteSpeciali[piatto.id_piatto] || ""}
                    onChange={(e) =>
                      handleRichiesteSpecialiChange(
                        piatto.id_piatto,
                        e.target.value
                      )
                    }
                  />
                </div>
              ))}
          </div>
        ))}
        <div>
          <h3>
            Extra: {extraCount} piatti, Totale: {extra}€
          </h3>
        </div>
        <button type="submit" className="btn-piatti">
          Invia Menu Compilato
        </button>
      </form>
    </div>
  );
}

export default CompilaMenu;
