import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { URLS } from "./Constants";
import moment from "moment";
import "./style-modal.css";
import "moment/locale/it";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/fr";
import "./style-modal.css";

function LoginPage() {
  const [prenotazioni, setPrenotazioni] = useState([]);
  const [numeroTelefono, setNumeroTelefono] = useState("");
  const [visualizzaMenu, setVisualizzaMenu] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const telefono = query.get("numero_telefono");

  useEffect(() => {
    const browserLang = navigator.language || navigator.userLanguage;
    moment.locale(browserLang);

    if (telefono) {
      handleLogin(telefono);
      setNumeroTelefono(telefono);
    }
  }, [telefono]);

  const handleLogin = async (numero_telefono) => {
    const response = await fetch(
      `${URLS.cercaPrenotazioniPerTelefono}?numero_telefono=${numero_telefono}`
    );
    const data = await response.json();
    if (response.ok) {
      const today = moment().startOf("day");
      const tomorrow = moment().add(1, "day").startOf("day");

      const prenotazioniValidi = data
        .flat()
        .filter((p) => p.data_ora && moment(p.data_ora).isValid());

      const prenotazioniFuturi = prenotazioniValidi.filter((p) =>
        moment(p.data_ora).isSame(tomorrow, "day")
      );

      const prenotazioniPassati = prenotazioniValidi.filter((p) =>
        moment(p.data_ora).isBefore(today, "day")
      );

      const prenotazioniOggiDomani = prenotazioniValidi.filter(
        (p) =>
          moment(p.data_ora).isSame(today, "day") ||
          moment(p.data_ora).isSame(tomorrow, "day")
      );

      setPrenotazioni(
        [...prenotazioniOggiDomani, ...prenotazioniPassati.slice(-3)].map(
          (p) => ({
            ...p,
            data_ora: moment(p.data_ora).format("LLLL"),
            isValid:
              moment(p.data_ora).isSame(today, "day") ||
              moment(p.data_ora).isSame(tomorrow, "day"),
          })
        )
      );
    } else {
      alert(data.message || "Errore durante la ricerca delle prenotazioni.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(numeroTelefono);
  };

  const handleVisualizzaMenu = async (numero_telefono, data_ora) => {
    const response = await fetch(
      `${URLS.visualizzaTuoMenu}?numero_telefono=${numero_telefono}&data=${data_ora}`
    );
    const data = await response.json();
    if (response.ok) {
      setVisualizzaMenu(data);
    } else {
      alert(data.message || "Errore durante la visualizzazione del menu.");
    }
  };

  return (
    <div className="login-page">
      <h1>Login per Compilare Menu</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Inserisci il tuo numero di telefono"
          value={numeroTelefono}
          onChange={(e) => setNumeroTelefono(e.target.value)}
          readOnly={Boolean(telefono)}
        />
        <button type="submit">Verifica Prenotazioni</button>
      </form>
      {prenotazioni.length > 0 && (
        <ul>
          {prenotazioni.map((p) => (
            <li className="mb-1" key={p.id_prenotazione}>
              {p.compilato ? (
                <span>
                  Menu già compilato per il{" "}
                  {moment(p.data_ora, "D MMMM YYYY HH:mm").format("LL")}{" "}
                  <button
                    onClick={() =>
                      handleVisualizzaMenu(
                        numeroTelefono,
                        moment(p.data_ora, "LLLL").format("YYYY-MM-DD")
                      )
                    }
                  >
                    👁️
                  </button>
                </span>
              ) : p.isValid ? (
                <button
                  onClick={() => {
                    const dataFormatted = encodeURIComponent(
                      moment(p.data_ora, "D MMMM YYYY HH:mm").format(
                        "YYYY-MM-DD"
                      )
                    );
                    window.location.href = `/compila-menu/${p.id_prenotazione}?data_ora=${dataFormatted}`;
                  }}
                >
                  Compila Menu per il{" "}
                  {moment(p.data_ora, "D MMMM YYYY HH:mm").format("LL")} (
                  {p.quando})
                </button>
              ) : (
                <span>
                  Compilazione per la data non disponibile:{" "}
                  {moment(p.data_ora, "D MMMM YYYY HH:mm").format("LL")}
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
      {visualizzaMenu && (
        <div className="menu-modal">
          <h2>Dettagli del Menu</h2>
          <ul>
            {visualizzaMenu.map((item, index) => (
              <li key={index}>
                {item.nome} - {item.descrizione}
                {item.extra && parseFloat(item.extra) > 0 && (
                  <span className="extra"> - Extra: {item.extra}€</span>
                )}
              </li>
            ))}
          </ul>
          <button onClick={() => setVisualizzaMenu(null)}>Chiudi</button>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
