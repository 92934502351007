import React, { useState, useEffect } from "react";
import "./../style.css"; // Assicurati che il percorso sia corretto
import { URLS } from "../components/Constants";

const allergeniList = [
  { id: 1, nome: "Glutine", icona: "🌾" },
  { id: 2, nome: "Latticini", icona: "🥛" },
  { id: 3, nome: "Uova", icona: "🥚" },
  { id: 4, nome: "Arachidi", icona: "🥜" },
  { id: 5, nome: "Pesce", icona: "🐟" },
  // Aggiungi altri allergeni qui
];

function GestionePiatto() {
  const [piatti, setPiatti] = useState([]);
  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [prezzo, setPrezzo] = useState("");
  const [portata, setPortata] = useState("");
  const [allergeni, setAllergeni] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPiatto, setEditingPiatto] = useState(null);

  useEffect(() => {
    fetchPiatti();
  }, []);

  const fetchPiatti = async () => {
    const response = await fetch(URLS.listaPiatti);
    const data = await response.json();
    const piattiConAllergeni = data.map((piatto) => ({
      ...piatto,
      allergeni: piatto.allergeni ? JSON.parse(piatto.allergeni) : [],
    }));
    setPiatti(piattiConAllergeni);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = editingPiatto ? URLS.aggiornaPiatto : URLS.aggiungiPiatto;
    const method = editingPiatto ? "PUT" : "POST";
    const body = JSON.stringify({
      id_piatto: editingPiatto?.id_piatto,
      nome,
      descrizione,
      prezzo,
      portata,
      allergeni,
    });

    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });

    const result = await response.json();
    if (result.message === "Operazione riuscita") {
      alert(
        editingPiatto
          ? "Piatto aggiornato con successo!"
          : "Piatto aggiunto con successo!"
      );
      fetchPiatti();
      resetForm();
    } else {
      alert("Si è verificato un errore durante l'operazione.");
    }
  };

  const handleDelete = async (id) => {
    const response = await fetch(URLS.cancellaPiatto, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id_piatto: id }),
    });
    const result = await response.json();
    if (result.message === "Piatto aggiornato con successo") {
      alert("Piatto cancellato (disattivato) con successo!");
      fetchPiatti();
    } else {
      alert(
        result.message ||
          "Errore durante la cancellazione (disattivazione) del piatto."
      );
    }
  };

  const handleAllergeniChange = (allergeneId) => {
    setAllergeni((prev) => {
      if (prev.includes(allergeneId)) {
        return prev.filter((id) => id !== allergeneId);
      } else {
        return [...prev, allergeneId];
      }
    });
  };

  const handleEdit = (piatto) => {
    setEditingPiatto(piatto);
    setNome(piatto.nome);
    setDescrizione(piatto.descrizione);
    setPrezzo(piatto.prezzo);
    setPortata(piatto.portata);
    setAllergeni(piatto.allergeni);
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setEditingPiatto(null);
    setNome("");
    setDescrizione("");
    setPrezzo("");
    setPortata("");
    setAllergeni([]);
    setIsModalOpen(false);
  };

  return (
    <div className="main-content">
      <div className="col-70 ml-4">
        <h1>Gestione Piatti</h1>
        <div className="lista-piatti">
          <div className="header-section">
            <h2>Lista di Piatti</h2>
            <button className="btn-piatti" onClick={() => setIsModalOpen(true)}>
              Aggiungi Piatto
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Descrizione</th>
                <th>Prezzo</th>
                <th>Portata</th>
                <th>Allergeni</th>
                <th>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {piatti.map((piatto) => (
                <tr key={piatto.id_piatto}>
                  <td>{piatto.id_piatto}</td>
                  <td>{piatto.nome}</td>
                  <td>{piatto.descrizione}</td>
                  <td>{`${piatto.prezzo}€`}</td>
                  <td>{piatto.portata}</td>
                  <td>
                    {piatto.allergeni.length > 0
                      ? piatto.allergeni
                          .map(
                            (id) =>
                              allergeniList.find((a) => a.id === id)?.icona
                          )
                          .join(", ")
                      : "Nessun allergene"}
                  </td>
                  <td>
                    <button
                      className="btn-piatti"
                      onClick={() => handleEdit(piatto)}
                    >
                      Modifica
                    </button>
                    <button
                      className="btn-piatti btn-delete"
                      onClick={() => handleDelete(piatto.id_piatto)}
                    >
                      Cancella
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {isModalOpen && (
          <div className="modal-container visible">
            <div className="modal-content">
              <span className="close" onClick={resetForm}>
                &times;
              </span>
              <h2>{editingPiatto ? "Modifica Piatto" : "Aggiungi Piatto"}</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="nome">Nome:</label>
                  <input
                    type="text"
                    id="nome"
                    name="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="descrizione">Descrizione:</label>
                  <textarea
                    id="descrizione"
                    name="descrizione"
                    value={descrizione}
                    onChange={(e) => setDescrizione(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="prezzo">Prezzo:</label>
                  <input
                    type="number"
                    id="prezzo"
                    name="prezzo"
                    value={prezzo}
                    onChange={(e) => setPrezzo(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="portata">Portata:</label>
                  <select
                    id="portata"
                    name="portata"
                    value={portata}
                    onChange={(e) => setPortata(e.target.value)}
                  >
                    <option value="">Seleziona una portata</option>
                    <option value="Antipasto">Antipasto</option>
                    <option value="Primo">Primo</option>
                    <option value="Secondo">Secondo</option>
                    <option value="Dolce">Dolce</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Allergeni:</label>
                  {allergeniList.map((allergene) => (
                    <div key={allergene.id} className="checkbox-group">
                      <input
                        type="checkbox"
                        id={`allergene-${allergene.id}`}
                        checked={allergeni.includes(allergene.id)}
                        onChange={() => handleAllergeniChange(allergene.id)}
                      />
                      <label htmlFor={`allergene-${allergene.id}`}>
                        {allergene.icona} {allergene.nome}
                      </label>
                    </div>
                  ))}
                </div>
                <button type="submit" className="btn-piatti">
                  {editingPiatto ? "Salva Modifiche" : "Aggiungi"}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GestionePiatto;
