import React, { useState, useEffect } from "react";
import moment from "moment";
import "./style-modal.css";

function MenuAssignmentModal({
  isOpen,
  onClose,
  onSave,
  onDelete,
  menu,
  menuList,
}) {
  const [currentWeek, setCurrentWeek] = useState(moment().startOf("week"));
  const [selectedDays, setSelectedDays] = useState(new Set());
  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [selectedTipoServizio, setSelectedTipoServizio] = useState("");
  const [deleteDate, setDeleteDate] = useState("");

  useEffect(() => {
    if (isOpen) {
      if (menu && menu.dayFrom && menu.dayTo) {
        const startDate = moment(menu.dayFrom);
        const endDate = moment(menu.dayTo);
        const newSelectedDays = new Set();

        while (startDate.isSameOrBefore(endDate)) {
          newSelectedDays.add(startDate.format("YYYY-MM-DD"));
          startDate.add(1, "days");
        }

        setSelectedDays(newSelectedDays);
        setSelectedMenuId(menu.id_menu);
        setSelectedTipoServizio(menu.tipo_servizio);
      } else {
        setSelectedDays(new Set());
        setSelectedMenuId("");
        setSelectedTipoServizio("");
      }
    }
  }, [isOpen, menu]);

  const handleDayChange = (formattedDay) => {
    const newDays = new Set(selectedDays);
    if (newDays.has(formattedDay)) {
      newDays.delete(formattedDay);
    } else {
      newDays.add(formattedDay);
    }
    setSelectedDays(newDays);
  };

  const weekDays = Array.from({ length: 7 }).map((_, i) =>
    currentWeek.clone().add(i, "days")
  );

  const navigateWeeks = (direction) => {
    setCurrentWeek(currentWeek.clone().add(direction * 7, "days"));
  };

  const handleSave = () => {
    if (selectedMenuId && selectedDays.size > 0 && selectedTipoServizio) {
      const sortedDays = Array.from(selectedDays).sort();
      const dayFrom = sortedDays[0];
      const dayTo = sortedDays[sortedDays.length - 1];
      onSave(selectedMenuId, dayFrom, dayTo, selectedTipoServizio);
      onClose();
    } else {
      alert("Seleziona un menu, almeno un giorno e un tipo di servizio.");
    }
  };

  const handleDelete = () => {
    if (selectedMenuId && deleteDate) {
      onDelete(selectedMenuId, deleteDate);
      onClose();
    } else {
      alert("Seleziona un menu e una data per cancellare l'assegnazione.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="modal-content">
        <h2>Assegna Menu</h2>
        <div className="modal-form-group">
          <label>Seleziona Menu:</label>
          <select
            value={selectedMenuId}
            onChange={(e) => setSelectedMenuId(e.target.value)}
          >
            <option value="">Seleziona un menu</option>
            {menuList.map((menu) => (
              <option key={menu.id_menu} value={menu.id_menu}>
                {menu.nome_menu}
              </option>
            ))}
          </select>
          <label>Tipo Servizio:</label>
          <select
            value={selectedTipoServizio}
            onChange={(e) => setSelectedTipoServizio(e.target.value)}
          >
            <option value="">Seleziona un tipo di servizio</option>
            <option value="pranzo">Pranzo</option>
            <option value="cena">Cena</option>
          </select>
        </div>
        <button className="arrow-week" onClick={() => navigateWeeks(-1)}>
          ← Settimana Precedente
        </button>
        <button className="arrow-week" onClick={() => navigateWeeks(1)}>
          Settimana Successiva →
        </button>
        <form onSubmit={handleSave}>
          {weekDays.map((day) => (
            <div key={day.format("YYYY-MM-DD")} className="modal-form-group">
              <input
                type="checkbox"
                id={day.format("YYYY-MM-DD")}
                checked={selectedDays.has(day.format("YYYY-MM-DD"))}
                onChange={() => handleDayChange(day.format("YYYY-MM-DD"))}
              />
              <label htmlFor={day.format("YYYY-MM-DD")}>
                {day.format("dddd D MMMM")}
              </label>
            </div>
          ))}
          <button className="modal-button" type="submit">
            Salva Assegnazioni
          </button>
        </form>
        <div className="modal-form-group">
          <label>Data per Cancellare:</label>
          <input
            type="date"
            value={deleteDate}
            onChange={(e) => setDeleteDate(e.target.value)}
          />
        </div>
        <button className="modal-button" type="button" onClick={handleDelete}>
          Cancella Assegnazione
        </button>
        <button className="modal-button" type="button" onClick={onClose}>
          Chiudi
        </button>
      </div>
    </div>
  );
}

export default MenuAssignmentModal;
