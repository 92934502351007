import React from 'react';

function DettaglioPiatto() {
  return (
    <div>
      {/* Contenuto del componente Dashboard */}
    </div>
  );
}

export default DettaglioPiatto;
