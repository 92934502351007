import React, { useState, useEffect } from "react";
import "./../style.css";
import { URLS } from "../components/Constants";

function CreaMenu() {
  const [piatti, setPiatti] = useState([]);
  const [menu, setMenu] = useState({
    nomeMenu: "",
    antipasti: [""],
    primi: [""],
    secondi: [""],
    dolci: [""],
  });

  useEffect(() => {
    fetchPiatti();
  }, []);

  const fetchPiatti = async () => {
    const response = await fetch(URLS.listaPiatti);
    const data = await response.json();
    setPiatti(data);
  };

  const handleChange = (e, portata, index) => {
    const { value } = e.target;
    setMenu((prevMenu) => {
      const newPortataArray = [...prevMenu[portata]];
      newPortataArray[index] = value;
      return {
        ...prevMenu,
        [portata]: newPortataArray,
      };
    });
  };

  const handleAddSelect = (portata) => {
    setMenu((prevMenu) => ({
      ...prevMenu,
      [portata]: [...prevMenu[portata], ""],
    }));
  };

  const handleRemoveSelect = (portata, index) => {
    setMenu((prevMenu) => ({
      ...prevMenu,
      [portata]: prevMenu[portata].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const piattiSelezionati = [
      ...menu.antipasti.filter((id) => id !== ""),
      ...menu.primi.filter((id) => id !== ""),
      ...menu.secondi.filter((id) => id !== ""),
      ...menu.dolci.filter((id) => id !== ""),
    ];

    const body = JSON.stringify({
      nome_menu: menu.nomeMenu,
      id_ristorante: 1,
      piatti: piattiSelezionati,
    });

    try {
      const response = await fetch(URLS.gestisciMenu, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });
      const result = await response.json();
      if (result.message) {
        alert("Menu creato con successo!");
      } else {
        alert("Errore nella creazione del menu.");
      }
    } catch (error) {
      console.error("Errore nell'invio del menu:", error);
    }
  };

  return (
    <div className="col-70 main-content">
      <h1 className="center">Crea Menu</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label>Nome Menu:</label>
          <input
            type="text"
            name="nomeMenu"
            value={menu.nomeMenu}
            onChange={(e) => setMenu({ ...menu, nomeMenu: e.target.value })}
            required
          />
        </div>
        {[
          {
            portata: "antipasti",
            label: "Antipasto",
            portataValue: "Antipasto",
          },
          { portata: "primi", label: "Primo Piatto", portataValue: "Primo" },
          {
            portata: "secondi",
            label: "Secondo Piatto",
            portataValue: "Secondo",
          },
          { portata: "dolci", label: "Dolce", portataValue: "Dolce" },
        ].map(({ portata, label, portataValue }) => (
          <div key={portata} className="form-group">
            <label>{label}:</label>
            {menu[portata].map((piattoId, index) => (
              <div key={index}>
                <select
                  value={piattoId}
                  onChange={(e) => handleChange(e, portata, index)}
                >
                  <option value="">Seleziona un {label}</option>
                  {piatti
                    .filter((piatto) => piatto.portata === portataValue)
                    .map((piatto) => (
                      <option key={piatto.id_piatto} value={piatto.id_piatto}>
                        {piatto.nome}
                      </option>
                    ))}
                </select>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveSelect(portata, index)}
                  >
                    Rimuovi
                  </button>
                )}
              </div>
            ))}
            <button type="button" onClick={() => handleAddSelect(portata)}>
              Aggiungi {label}
            </button>
          </div>
        ))}
        <button type="submit" className="btn-piatti">
          Crea Menu
        </button>
      </form>
    </div>
  );
}

export default CreaMenu;
