import React, { useState, useEffect } from "react";
import "./../style.css";
import { URLS } from "../components/Constants";
import html2pdf from "html2pdf.js";

function GrigliaMaitreFE() {
  const [servizio, setServizio] = useState("pranzo");
  const [ordinePiatti, setOrdinePiatti] = useState([]);
  const [availableTables, setAvailableTables] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchPiatti(servizio);
    fetchAvailableTables();
  }, [servizio, refresh]);

  const fetchPiatti = async (servizio) => {
    const response = await fetch(
      `${URLS.getGridMaitre}?id_ristorante=1&servizio=${servizio}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    if (data && Array.isArray(data)) {
      setOrdinePiatti(data);
    } else {
      console.error("La risposta dell'API non è un array", data);
      setOrdinePiatti([]);
    }
  };

  const fetchAvailableTables = async () => {
    const response = await fetch(`${URLS.getTavoliLiberi}?id_ristorante=1`);
    const data = await response.json();
    setAvailableTables(data);
  };

  const handleAssignTable = async () => {
    if (!selectedReservation || !selectedTable) {
      alert("Seleziona una prenotazione e un tavolo.");
      return;
    }

    const response = await fetch(URLS.assignTable, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_prenotazione: selectedReservation,
        id_tavolo: selectedTable,
      }),
    });

    if (response.ok) {
      alert("Tavolo assegnato con successo!");
      setRefresh(!refresh); // Trigger a refresh
    } else {
      alert("Errore durante l'assegnazione del tavolo.");
    }
  };

  const handlePrint = () => {
    const element = document.getElementById("griglia-maitre-table");
    html2pdf().from(element).save();
  };

  const renderTable = () => {
    const tables = [...new Set(ordinePiatti.map((item) => item.numero_tavolo))];

    const dishes = ordinePiatti.reduce((acc, item) => {
      item.piatti.split(";").forEach((piatto) => {
        const [quantity, dish] = piatto.split("x").map((s) => s.trim());
        const [name, variations] = dish
          .split("(")
          .map((s) => s.trim().replace(")", ""));
        if (!acc[name]) acc[name] = {};
        acc[name][item.numero_tavolo] = {
          quantity,
          variations: variations || "",
        };
      });
      return acc;
    }, {});

    const specialRequests = tables.reduce((acc, table) => {
      const requests = ordinePiatti
        .filter((item) => item.numero_tavolo === table)
        .map((item) =>
          item.piatti
            .split(";")
            .map((piatto) => {
              const [quantity, dish] = piatto.split("x").map((s) => s.trim());
              const [name, variations] = dish
                .split("(")
                .map((s) => s.trim().replace(")", ""));
              return variations ? `${variations} per ${name}` : null;
            })
            .filter(Boolean)
            .join(", ")
        );
      acc[table] = requests.join(", ");
      return acc;
    }, {});

    return (
      <table className="mt-2" id="griglia-maitre-table">
        <thead>
          <tr>
            <th>Piatti</th>
            {tables.map((table, index) => (
              <th key={index}>{table}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(dishes).map((dish, index) => (
            <tr key={index}>
              <td>{dish}</td>
              {tables.map((table, index) => (
                <td key={index}>
                  {dishes[dish][table]
                    ? `${dishes[dish][table].quantity} (${dishes[dish][table].variations})`
                    : ""}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td>Variazioni / Richieste Speciali</td>
            {tables.map((table, index) => (
              <td key={index}>{specialRequests[table]}</td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className="main-content">
      <div className="col-70 ml-4">
        <h1>Griglia Maitre - {new Date().toLocaleDateString()}</h1>
        <div className="mt-2">
          <button className="btn-piatti" onClick={() => setServizio("pranzo")}>
            Pranzo
          </button>
          <button className="btn-piatti" onClick={() => setServizio("cena")}>
            Cena
          </button>
          <button className="btn-piatti-2" onClick={handlePrint}>
            Stampa
          </button>
        </div>
        <div className="mt-2">
          <button className="btn-piatti" onClick={handleAssignTable}>
            Assegna Tavolo
          </button>
        </div>
        <div className="mt-2">
          <label>Prenotazioni:</label>
          <select
            value={selectedReservation}
            onChange={(e) => setSelectedReservation(e.target.value)}
          >
            <option value="">Seleziona una prenotazione</option>
            {ordinePiatti.map((reservation) => (
              <option
                key={reservation.id_prenotazione}
                value={reservation.id_prenotazione}
              >
                {reservation.cognome} -{" "}
                {reservation.numero_tavolo
                  ? reservation.numero_tavolo
                  : "Da assegnare tavolo"}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <label>Tavoli:</label>
          <select
            value={selectedTable}
            onChange={(e) => setSelectedTable(e.target.value)}
          >
            <option value="">Seleziona un tavolo</option>
            {availableTables.map((table) => (
              <option key={table.id_tavolo} value={table.id_tavolo}>
                Tavolo {table.numero_tavolo} - Capacità {table.capacita}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <h2>Ordini:</h2>
          {renderTable()}
        </div>
      </div>
    </div>
  );
}

export default GrigliaMaitreFE;
