import React, { useState, useEffect } from "react";
import { URLS } from "../components/Constants";

function VisualizzaMenu() {
  const [menu, setMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [piattiMenu, setPiattiMenu] = useState([]);
  const [allPiatti, setAllPiatti] = useState([]);
  const [modaleVisibile, setModaleVisibile] = useState(false);

  useEffect(() => {
    fetchMenu();
  }, []);

  const fetchMenu = async () => {
    const response = await fetch(URLS.getAllMenus);
    const data = await response.json();
    setMenu(data);
  };

  const fetchPiattiMenu = async (idMenu) => {
    const response = await fetch(`${URLS.getPiattiMenu}?id_menu=${idMenu}`);
    const piattiMenu = await response.json();
    setPiattiMenu(
      piattiMenu.map((piatto) => ({
        ...piatto,
        allergeni: piatto.allergeni ? JSON.parse(piatto.allergeni) : [],
      }))
    );
  };

  const fetchAllPiatti = async () => {
    const response = await fetch(URLS.listaPiatti);
    const data = await response.json();
    setAllPiatti(data);
  };

  async function handleDelete(idMenu) {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler cancellare questo menu?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${URLS.deleteMenu}?id_menu=${idMenu}`, {
        method: "DELETE",
      });

      if (response.ok) {
        alert("Menu cancellato con successo!");
        // Aggiorna la lista dei menu o esegui altre azioni necessarie
        fetchMenu();
      } else {
        alert("Errore durante la cancellazione del menu.");
      }
    } catch (error) {
      console.error("Errore:", error);
      alert("Errore durante la cancellazione del menu.");
    }
  }

  const handleEdit = async (idMenu) => {
    await fetchPiattiMenu(idMenu);
    await fetchAllPiatti();
    setSelectedMenu(menu.find((m) => m.id_menu === idMenu));
    setModaleVisibile(true);
  };

  const handleAssignWeek = (idMenu) => {
    console.log("Assegna il menu per la settimana:", idMenu);
  };

  const handleCloseModal = () => {
    setModaleVisibile(false);
    setSelectedMenu(null);
    setPiattiMenu([]);
    setAllPiatti([]);
  };

  const handleUpdateMenu = async () => {
    console.log("Updating menu with ID:", selectedMenu.id_menu);
    console.log("Payload:", {
      ...selectedMenu,
      piatti: piattiMenu,
    });

    try {
      const response = await fetch(`${URLS.updateMenu}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...selectedMenu,
          piatti: piattiMenu,
        }),
      });

      if (response.ok) {
        fetchMenu();
        handleCloseModal();
      } else {
        const errorData = await response.json();
        console.error("Errore nell'aggiornamento del menu:", errorData);
        alert("Errore nell'aggiornamento del menu");
      }
    } catch (error) {
      console.error("Errore di rete:", error);
      alert("Errore di rete durante l'aggiornamento del menu");
    }
  };

  const handleSelectChange = (index, value) => {
    const updatedPiattiMenu = [...piattiMenu];
    updatedPiattiMenu[index] = allPiatti.find(
      (piatto) => piatto.id_piatto === parseInt(value)
    );
    setPiattiMenu(updatedPiattiMenu);
  };

  const handleAddPiatto = () => {
    setPiattiMenu([...piattiMenu, { id_piatto: "", portata: "" }]);
  };

  return (
    <div className="main-content">
      <div className={`table-container ${modaleVisibile ? "expanded" : ""}`}>
        <h1>Menu Disponibili</h1>
        <table>
          <thead>
            <tr>
              <th>Nome Menu</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {menu.map((m) => (
              <tr key={m.id_menu}>
                <td>{m.nome_menu}</td>
                <td>
                  <button
                    className="btn-piatti"
                    onClick={() => handleEdit(m.id_menu)}
                  >
                    Modifica Menu
                  </button>
                  <button
                    className="btn-piatti btn-delete"
                    onClick={() => handleDelete(m.id_menu)}
                  >
                    Cancella
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modaleVisibile && (
        <div className="modal-container visible">
          <div className="modal-content">
            <h2>Modifica Menu: {selectedMenu.nome_menu}</h2>
            <form>
              {piattiMenu.map((piatto, index) => (
                <div className="select-piatti" key={index}>
                  <label>Portata {index + 1}:</label>
                  <select
                    value={piatto.id_piatto}
                    onChange={(e) => handleSelectChange(index, e.target.value)}
                  >
                    {allPiatti.map((p) => (
                      <option key={p.id_piatto} value={p.id_piatto}>
                        {p.nome} - {p.portata}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </form>
            <button className="button-cancel" onClick={handleAddPiatto}>
              Aggiungi Portata
            </button>
            <button className="btn-piatti" onClick={handleUpdateMenu}>
              Salva Modifiche
            </button>
            <button
              className="btn-piatti btn-delete"
              onClick={handleCloseModal}
            >
              Chiudi
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VisualizzaMenu;
