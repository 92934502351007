import React, { useState, useEffect } from "react";
import { Bar, Pie } from "react-chartjs-2";
import moment from "moment";
import "chart.js/auto";
import { URLS } from "../components/Constants";

function Report() {
  const [monthlyClients, setMonthlyClients] = useState(0);
  const [mostRequestedDishes, setMostRequestedDishes] = useState({});
  const [totalReservations, setTotalReservations] = useState([]);
  const [lastFiveReservations, setLastFiveReservations] = useState([]);
  const [dishesChartData, setDishesChartData] = useState(null);
  const [reservationsChartData, setReservationsChartData] = useState(null);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      const response = await fetch(URLS.statistica);
      const data = await response.json();

      setMonthlyClients(data.persone);
      setMostRequestedDishes(data.piatti_consumati);
      setTotalReservations(data.prenotazioni);
      setLastFiveReservations(data.prenotazioni.slice(-5));

      prepareChartData(data);
    } catch (error) {
      console.error("Errore nel caricamento delle statistiche:", error);
    }
  };

  const prepareChartData = (data) => {
    if (
      data.piatti_consumati &&
      Object.keys(data.piatti_consumati).length > 0
    ) {
      const dishesData = {
        labels: Object.keys(data.piatti_consumati),
        datasets: [
          {
            data: Object.values(data.piatti_consumati),
            backgroundColor: [
              "#99d8c9",
              "#075d49",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
            ],
          },
        ],
      };
      setDishesChartData(dishesData);
    }

    if (data.prenotazioni && data.prenotazioni.length > 0) {
      const reservationsData = {
        labels: data.prenotazioni.map((res) =>
          moment(res.data).format("DD/MM/YYYY")
        ),
        datasets: [
          {
            label: "Numero di Prenotazioni",
            data: data.prenotazioni.map((res) => res.count),
            backgroundColor: "rgba(75,192,192,0.6)",
          },
        ],
      };
      setReservationsChartData(reservationsData);
    }
  };

  return (
    <div className="col-70 main-content">
      <h1>Report Statistiche</h1>
      <div className="report-container">
        <div className="report-item">
          <h2>Clienti dell'ultimo mese</h2>
          <p>{monthlyClients}</p>
          <h3>Ultime 5 Prenotazioni</h3>
          <ul>
            {lastFiveReservations.map((res, index) => (
              <li key={index}>
                {moment(res.data).format("DD/MM/YYYY")} - {res.count}{" "}
                prenotazioni
              </li>
            ))}
          </ul>
        </div>
        <div className="report-item">
          <h2>Piatti più richiesti</h2>
          {dishesChartData ? (
            <Pie data={dishesChartData} />
          ) : (
            <p>Caricamento...</p>
          )}
        </div>
        <div className="report-item full-width">
          <h2>Numero di Prenotazioni</h2>
          {reservationsChartData ? (
            <Bar data={reservationsChartData} />
          ) : (
            <p>Caricamento...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Report;
