import React, { useState, useEffect } from "react";
import "./../style.css";
import { URLS } from "../components/Constants";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SortableColumn from "../components/SortableColumn";
import html2pdf from "html2pdf.js";

function GrigliaChef() {
  const [servizio, setServizio] = useState("pranzo");
  const [ordinePiatti, setOrdinePiatti] = useState([]);
  const [columns, setColumns] = useState([
    { id: "piatto", label: "Piatto" },
    { id: "quantita_totale", label: "Quantità" },
    { id: "richieste_speciali", label: "Richieste Speciali" },
  ]);
  const [invert, setInvert] = useState(false);
  const [datiCalcolati, setDatiCalcolati] = useState({
    ospiti: 0,
    primi: 0,
    secondi: 0,
  });

  useEffect(() => {
    fetchPiatti(servizio);
    fetchDatiCalcolati(servizio);
  }, [servizio]);

  const fetchPiatti = async (servizio) => {
    const response = await fetch(`${URLS.getChef}?servizio=${servizio}`);
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    if (data && Array.isArray(data)) {
      setOrdinePiatti(data);
    } else {
      console.error("La risposta dell'API non è un array", data);
      setOrdinePiatti([]);
    }
  };

  const fetchDatiCalcolati = async (servizio) => {
    const response = await fetch(
      `${URLS.getDatiCalcolati}?servizio=${servizio}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    setDatiCalcolati(data);
  };

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = columns.findIndex((col) => col.id === active.id);
      const newIndex = columns.findIndex((col) => col.id === over.id);
      const updatedColumns = Array.from(columns);
      const [movedColumn] = updatedColumns.splice(oldIndex, 1);
      updatedColumns.splice(newIndex, 0, movedColumn);
      setColumns(updatedColumns);
    }
  };

  const SortableTh = ({ column }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: column.id });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    return (
      <th
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        key={column.id}
      >
        {column.label}
      </th>
    );
  };

  const renderTable = () => {
    if (invert) {
      return (
        <table className="mt-2" id="griglia-table">
          <thead>
            <tr>
              <th>Colonne/Righe</th>
              {ordinePiatti.map((_, index) => (
                <th key={index}>Riga {index + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {columns.map((column, columnIndex) => (
              <tr key={columnIndex}>
                <td>{column.label}</td>
                {ordinePiatti.map((item, rowIndex) => (
                  <td key={rowIndex}>{item[column.id]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <table className="mt-2" id="griglia-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <SortableColumn column={column} key={column.id}>
                {column.label}
              </SortableColumn>
            ))}
          </tr>
        </thead>
        <tbody>
          {ordinePiatti.map((item, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column.id}>{item[column.id]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handlePrint = () => {
    const element = document.getElementById("griglia-table");
    html2pdf().from(element).save();
  };

  return (
    <div className="main-content">
      <div className="col-70 ml-4">
        <h1>Griglia Ordini - {new Date().toLocaleDateString()}</h1>
        <div className="mt-2">
          <button className="btn-piatti" onClick={() => setServizio("pranzo")}>
            Pranzo
          </button>
          <button className="btn-piatti" onClick={() => setServizio("cena")}>
            Cena
          </button>
        </div>
        <div className="mt-2">
          <label>
            <input
              type="checkbox"
              checked={invert}
              onChange={(e) => setInvert(e.target.checked)}
            />
            Inverti righe e colonne
          </label>
        </div>
        <div className="mt-2">
          <button className="btn-piatti" onClick={handlePrint}>
            Stampa
          </button>
        </div>
        <div className="info-box">
          <p>PAX: {datiCalcolati.ospiti}</p>
          <p>Secondi scelti: {datiCalcolati.secondi}</p>
          <p>Primi scelti: {datiCalcolati.primi}</p>
        </div>
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={columns}
            strategy={verticalListSortingStrategy}
          >
            {renderTable()}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}

export default GrigliaChef;
