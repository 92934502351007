import React, { useState } from "react";
import Modal from "./Modal"; // Assumi che hai un componente Modal generico
import "./../style.css";
import { URLS } from "../components/Constants";

function Impostazioni() {
  const [modalOpen, setModalOpen] = useState({
    activityInfo: false,
    customMessage: false,
    emailConfig: false,
  });

  // Questo è un esempio di funzione per aprire e chiudere i modal
  const handleModalToggle = (key) => {
    setModalOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  // Componente di singola voce di impostazione
  function SettingItem({ title, description, onEdit }) {
    return (
      <div className="setting-item">
        <h2>{title}</h2>
        <p>{description}</p>
        <button onClick={onEdit}>Modifica</button>
      </div>
    );
  }

  function ActivityInfoForm() {
    const [businessName, setBusinessName] = useState("");
    const [address, setAddress] = useState("");

    const handleSubmit = (event) => {
      event.preventDefault();
      console.log("Salvare", { businessName, address });
    };

    return (
      <form onSubmit={handleSubmit}>
        <label>
          Nome Attività:
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </label>
        <label>
          Indirizzo:
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </label>
        <button type="submit">Salva</button>
      </form>
    );
  }

  function CustomMessageForm({ onClose }) {
    const [message, setMessage] = useState("");

    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        const response = await fetch(URLS.pushMessaggioPersonalizzato, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idRistorante: 1, // Utilizzo l'ID 1 come esempio
            chiave: "messaggio_menu",
            valore: message,
          }),
        });
        if (response.ok) {
          alert("Messaggio salvato con successo!");
          onClose(); // Chiudi il modale dopo il salvataggio
        } else {
          throw new Error("Non è stato possibile salvare il messaggio");
        }
      } catch (error) {
        alert(`Errore: ${error.message}`);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <label>
          Inserisci il tuo messaggio personalizzato:
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            cols="50"
            placeholder="Scrivi qui il messaggio che verrà mostrato nella pagina 'Compila Menu'"
          />
        </label>
        <div>
          <button type="submit" style={{ marginRight: "10px" }}>
            Salva
          </button>
        </div>
      </form>
    );
  }

  function EmailConfigForm() {}

  return (
    <div className="col-70 main-content">
      <div className="settings-grid">
        <SettingItem
          title="Informazioni attività"
          description="Modifica le informazioni relative alla tua attività."
          onEdit={() => handleModalToggle("activityInfo")}
        />
        <SettingItem
          title="Messaggio personalizzato"
          description="Imposta un messaggio personalizzato da includere nei menu."
          onEdit={() => handleModalToggle("customMessage")}
        />
        <SettingItem
          title="Configurazione email"
          description="Configura le impostazioni email per inviare i menu. [WORK IN PROGRESS]"
          onEdit={() => handleModalToggle("emailConfig")}
        />

        {/* Modali per ciascuna impostazione */}
        {modalOpen.activityInfo && (
          <Modal
            onClose={() => handleModalToggle("activityInfo")}
            title="Informazioni attività"
          >
            <ActivityInfoForm />
          </Modal>
        )}
        {modalOpen.customMessage && (
          <Modal
            onClose={() => handleModalToggle("customMessage")}
            title="Messaggio personalizzato"
          >
            <CustomMessageForm />
          </Modal>
        )}
        {modalOpen.emailConfig && (
          <Modal
            onClose={() => handleModalToggle("emailConfig")}
            title="Configurazione email [WORK IN PROGRESS]"
          >
            <EmailConfigForm />
          </Modal>
        )}
      </div>
    </div>
  );
}

export default Impostazioni;
