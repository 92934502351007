import React, { useState, useEffect } from "react";
import { URLS } from "../components/Constants"; // Assicurati che il percorso sia corretto

function Dashboard() {
  const [statistiche, setStatistiche] = useState({});
  const [prenotazioni, setPrenotazioni] = useState([]);

  useEffect(() => {
    // Chiamata API per le statistiche
    fetch(URLS.statistica)
      .then((response) => response.json()) // Ottieni direttamente il JSON
      .then((data) => {
        console.log(data); // Stampa i dati JSON
        setStatistiche(data);
      })
      .catch((error) => console.error("Error:", error));

    console.log(URLS.statistica); // Dovrebbe stampare l'URL completo
    console.log(URLS.prenotazioni); // Dovrebbe stampare l'URL completo

    // Chiamata API per le prenotazioni recenti
    fetch(URLS.prenotazioni)
      .then((response) => response.json())
      .then((data) => setPrenotazioni(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div className="main-content col-70">
      <h1>Dashboard</h1>

      {/* Attività Recenti */}
      <div className="recent-activity">
        <h2>Attività Ultimi 28 giorni</h2>
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Piatti Consumati</th>
              <th>Prenotazioni</th>
              <th>Persone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{new Date().toISOString().split("T")[0]}</td>
              <td>
                {statistiche.piatti_consumati &&
                  Object.entries(statistiche.piatti_consumati).map(
                    ([piatto, quantita], index) => (
                      <div key={index}>
                        {piatto}: {quantita}
                      </div>
                    )
                  )}
              </td>
              <td>
                {statistiche.prenotazioni ? statistiche.prenotazioni.length : 0}
              </td>
              <td>{statistiche.persone}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Tabella Prenotazioni Recenti */}
      <div className="recent-bookings">
        <h2>Prenotazioni Recenti</h2>
        <table>
          <thead>
            <tr>
              <th>ID Prenotazione</th>
              <th>Cognome</th>
              <th>ID Tavolo</th>
              <th>Data e Ora</th>
              <th>Stato</th>
            </tr>
          </thead>
          <tbody>
            {prenotazioni.length > 0 ? (
              prenotazioni.map((prenotazione) => (
                <tr key={prenotazione.id_prenotazione}>
                  <td>{prenotazione.id_prenotazione}</td>
                  <td>{prenotazione.cognome}</td>
                  <td>{prenotazione.id_tavolo}</td>
                  <td>{new Date(prenotazione.data_ora).toLocaleString()}</td>
                  <td>{prenotazione.stato}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">Nessuna prenotazione recente trovata.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;
