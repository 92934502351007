import React, { useState } from "react";
import Papa from "papaparse";
import "./../style.css";
import { URLS } from "../components/Constants";

function InserisciPrenotazione() {
  const [utenti, setUtenti] = useState([]);
  const [newUserVisible, setNewUserVisible] = useState(false);
  const [newUserData, setNewUserData] = useState({
    cognome: "",
    numero_telefono: "",
  });
  const [formData, setFormData] = useState({
    id_utente: "",
    cognome: "",
    numero_telefono: "",
    id_ristorante: 1,
    ospiti: 1,
    checkin: "",
    checkout: "",
    pensione: "",
  });
  const [query, setQuery] = useState("");
  const [messaggio, setMessaggio] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      let updatedFormData = {
        ...prevState,
        [name]: value,
      };

      console.log("Campo modificato:", name, value);

      // Se il campo modificato è "pensione"
      if (name === "pensione") {
        if (!newUserVisible && formData.id_utente) {
          const selectedUser = utenti.find(
            (utente) => utente.id_utente === formData.id_utente
          );
          console.log("Utente selezionato:", selectedUser);
          if (selectedUser) {
            updatedFormData = {
              ...updatedFormData,
              cognome: selectedUser.cognome,
              numero_telefono: selectedUser.numero_telefono,
            };
          }
        } else if (newUserVisible) {
          updatedFormData = {
            ...updatedFormData,
            cognome: newUserData.cognome,
            numero_telefono: newUserData.numero_telefono,
          };
        }
      }

      console.log("Dati del form aggiornati:", updatedFormData);

      return updatedFormData;
    });
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 2) {
      const response = await fetch(
        `${URLS.cercaUtente}?query=${encodeURIComponent(value)}`
      );
      const data = await response.json();
      const uniqueData = data.reduce((acc, curr) => {
        if (
          !acc.find(
            (item) =>
              item.numero_telefono === curr.numero_telefono &&
              item.cognome === curr.cognome
          )
        ) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setUtenti(uniqueData);
      setDropdownVisible(true);
    } else {
      setUtenti([]);
      setDropdownVisible(false);
    }
  };

  const handleSelectUser = (id_utente) => {
    const selectedUser = utenti.find(
      (utente) => utente.id_utente === id_utente
    );
    console.log("Utente selezionato:", selectedUser);
    if (id_utente === "new") {
      setNewUserVisible(true);
      setFormData((prevState) => ({
        ...prevState,
        id_utente: "",
        cognome: newUserData.cognome,
        numero_telefono: newUserData.numero_telefono,
      }));
      console.log("Dati del form per nuovo utente:", {
        ...formData,
        id_utente: "",
        cognome: newUserData.cognome,
        numero_telefono: newUserData.numero_telefono,
      });
    } else {
      setNewUserVisible(false);
      setFormData((prevState) => ({
        ...prevState,
        id_utente: id_utente,
        cognome: selectedUser.cognome,
        numero_telefono: selectedUser.numero_telefono,
      }));
      console.log("Dati del form dopo la selezione dell'utente:", {
        ...formData,
        id_utente: id_utente,
        cognome: selectedUser.cognome,
        numero_telefono: selectedUser.numero_telefono,
      });
    }
    setDropdownVisible(false);
  };

  const handleAddNewUser = async () => {
    try {
      const response = await fetch(URLS.aggiungiUtente, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUserData),
      });
      const data = await response.json();
      if (data.id_utente) {
        setFormData((prev) => ({
          ...prev,
          id_utente: data.id_utente,
          cognome: newUserData.cognome,
          numero_telefono: newUserData.numero_telefono,
        }));
        setMessaggio("Utente aggiunto con successo!");
        setNewUserVisible(false);
        return data.id_utente;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setMessaggio("Errore: " + error.message);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userId = formData.id_utente;
    if (newUserVisible) {
      userId = await handleAddNewUser();
      if (!userId) return;
      setFormData((prev) => ({
        ...prev,
        id_utente: userId,
        cognome: newUserData.cognome,
        numero_telefono: newUserData.numero_telefono,
      }));
    }

    // Aggiorna formData per includere cognome e numero_telefono anche se non sono visibili
    if (!newUserVisible && userId) {
      const selectedUser = utenti.find((utente) => utente.id_utente === userId);
      if (selectedUser) {
        setFormData((prev) => ({
          ...prev,
          cognome: selectedUser.cognome,
          numero_telefono: selectedUser.numero_telefono,
        }));
      }
    }

    try {
      const response = await fetch(URLS.inserisciPrenotazione, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result.success) {
        setMessaggio("Prenotazione inserita con successo!");
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setMessaggio("Errore: " + error.message);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const cleanedData = results.data
          .map((row) => ({
            id_utente: row.id_utente || null,
            id_ristorante: row.id_ristorante || null,
            ospiti: row.ospiti || null,
            checkin: row.checkin || null,
            checkout: row.checkout || null,
            pensione: row.pensione || null,
          }))
          .filter(
            (row) =>
              row.id_utente &&
              row.id_ristorante &&
              row.ospiti &&
              row.checkin &&
              row.checkout
          );

        if (cleanedData.length === 0) {
          setMessaggio("Nessuna prenotazione valida trovata nel file CSV.");
          return;
        }

        setCsvData(cleanedData);
        setMessaggio("File CSV caricato correttamente. Pronto per l'invio.");
      },
    });
  };

  const handleCsvSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(URLS.inserisciPrenotazione, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prenotazioni: csvData }),
      });
      const result = await response.json();
      if (result.success) {
        setMessaggio("Prenotazioni inserite con successo!");
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setMessaggio("Errore: " + error.message);
    }
  };

  return (
    <div className="col-70 main-content">
      <div className="aggiungi-piatto col-100" id="sezione-aggiungi-piatto">
        <div className="col-70 ml-4">
          <h2>Aggiungi PRENOTAZIONE</h2>
          <form onSubmit={handleSubmit} className="form-container">
            <div className="input-with-dropdown">
              <input
                type="text"
                placeholder="Cerca utente per telefono o cognome..."
                value={query}
                onChange={handleSearchChange}
              />
              {dropdownVisible && (
                <div className="dropdown">
                  {utenti.map((utente) => (
                    <div
                      key={utente.id_utente}
                      className="dropdown-item"
                      onClick={() => handleSelectUser(utente.id_utente)}
                    >
                      {utente.cognome} ({utente.numero_telefono})
                    </div>
                  ))}
                  <div
                    className="dropdown-item button-like"
                    onClick={() => handleSelectUser("new")}
                  >
                    + Aggiungi nuovo utente
                  </div>
                </div>
              )}
            </div>
            {newUserVisible && (
              <>
                <input
                  type="text"
                  placeholder="Cognome"
                  name="cognome"
                  value={newUserData.cognome}
                  onChange={handleNewUserChange}
                />
                <input
                  type="text"
                  placeholder="Numero di telefono"
                  name="numero_telefono"
                  value={newUserData.numero_telefono}
                  onChange={handleNewUserChange}
                />
              </>
            )}
            {!newUserVisible && formData.id_utente && (
              <>
                <input
                  type="text"
                  placeholder="Cognome"
                  name="cognome"
                  value={formData.cognome}
                  readOnly
                />
                <input
                  type="text"
                  placeholder="Numero di telefono"
                  name="numero_telefono"
                  value={formData.numero_telefono}
                  readOnly
                />
              </>
            )}
            <label htmlFor="ospiti">Numero di ospiti:</label>
            <input
              type="number"
              id="ospiti"
              name="ospiti"
              value={formData.ospiti}
              onChange={(e) => handleChange(e)}
              min="1"
              required
            />
            <label htmlFor="checkin">Check-in:</label>
            <input
              type="datetime-local"
              id="checkin"
              name="checkin"
              value={formData.checkin}
              onChange={handleChange}
              required
            />
            <label htmlFor="checkout">Check-out:</label>
            <input
              type="datetime-local"
              id="checkout"
              name="checkout"
              value={formData.checkout}
              onChange={handleChange}
              required
            />
            <label htmlFor="pensione">Trattamento:</label>
            <select
              id="pensione"
              name="pensione"
              value={formData.pensione}
              onChange={handleChange}
              required
            >
              <option value="">Seleziona il trattamento</option>
              <option value="mezza-pensione-pranzo">
                Mezza Pensione (solo pranzo)
              </option>
              <option value="mezza-pensione-cena">
                Mezza Pensione (solo cena)
              </option>
              <option value="pensione-completa">Pensione Completa</option>
            </select>
            <input
              type="submit"
              className="btn-piatti"
              value="Inserisci Prenotazione"
            />
          </form>
          <h2>Carica Prenotazioni da CSV</h2>
          <form onSubmit={handleCsvSubmit} className="form-container">
            <input type="file" accept=".csv" onChange={handleFileUpload} />
            <input
              type="submit"
              className="btn-piatti"
              value="Carica Prenotazioni"
            />
          </form>
          {messaggio && (
            <div>
              <p>{messaggio}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InserisciPrenotazione;
