import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../components/Constants";
import "./LoginForm.css"; // Assicurati di creare un file CSS simile per questa pagina

export default function RecuperaPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(URLS.APIURL + "recupera-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("Email per il recupero della password inviata.");
        setEmail("");
      } else {
        setError("Email non trovata.");
      }
    } catch (error) {
      console.error("Failed to send recovery email", error);
      setError("Si è verificato un errore.");
    }
  };

  return (
    <div className="recupera-password-container">
      <form onSubmit={handleSubmit} className="recupera-password-form">
        <h2>Recupera Password</h2>
        {message && <p className="message">{message}</p>}
        {error && <p className="error">{error}</p>}
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Invia</button>
      </form>
    </div>
  );
}
