import React, { useState, useEffect } from "react";
import "./../style.css";
import { URLS } from "../components/Constants";
import { useNavigate } from "react-router-dom"; // Importa useNavigate

function GrigliaMaitre() {
  const [servizio, setServizio] = useState("pranzo");
  const [ordinePiatti, setOrdinePiatti] = useState([]);
  const [availableTables, setAvailableTables] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [invert, setInvert] = useState(false);
  const navigate = useNavigate(); // Inizializza useNavigate

  useEffect(() => {
    fetchPiatti(servizio);
    fetchAvailableTables();
  }, [servizio, refresh]);

  const fetchPiatti = async (servizio) => {
    const response = await fetch(
      `${URLS.getGridMaitre}?id_ristorante=1&servizio=${servizio}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    if (data && Array.isArray(data)) {
      setOrdinePiatti(data);
    } else {
      console.error("La risposta dell'API non è un array", data);
      setOrdinePiatti([]);
    }
  };

  const fetchAvailableTables = async () => {
    const response = await fetch(`${URLS.getTavoliLiberi}?id_ristorante=1`);
    const data = await response.json();
    setAvailableTables(data);
  };

  const handleAssignTable = async () => {
    if (!selectedReservation || !selectedTable) {
      alert("Seleziona una prenotazione e un tavolo.");
      return;
    }

    const response = await fetch(URLS.assignTable, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_prenotazione: selectedReservation,
        id_tavolo: selectedTable,
      }),
    });

    if (response.ok) {
      alert("Tavolo assegnato con successo!");
      setRefresh(!refresh); // Trigger a refresh
    } else {
      alert("Errore durante l'assegnazione del tavolo.");
    }
  };

  const renderTable = () => {
    if (invert) {
      return (
        <table className="mt-2">
          <thead>
            <tr>
              <th>Colonne/Righe</th>
              {ordinePiatti.map((_, index) => (
                <th key={index}>Riga {index + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[
              { id: "numero_tavolo", label: "Tavolo" },
              { id: "cognome", label: "Cognome" },
              { id: "ospiti", label: "Ospiti" },
              { id: "piatti", label: "Piatti" },
            ].map((column, columnIndex) => (
              <tr key={columnIndex}>
                <td>{column.label}</td>
                {ordinePiatti.map((item, rowIndex) => (
                  <td key={rowIndex}>{item[column.id]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <table className="mt-2">
        <thead>
          <tr>
            <th>Tavolo</th>
            <th>Cognome</th>
            <th>Ospiti</th>
            <th>Piatti</th>
          </tr>
        </thead>
        <tbody>
          {ordinePiatti.map((item, index) => (
            <tr key={index}>
              <td>{item.numero_tavolo}</td>
              <td>{item.cognome}</td>
              <td>{item.ospiti}</td>
              <td>{item.piatti}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="main-content">
      <div className="col-70 ml-4">
        <h1>Griglia Maitre - {new Date().toLocaleDateString()}</h1>
        <div className="mt-2">
          <button className="btn-piatti" onClick={() => setServizio("pranzo")}>
            Pranzo
          </button>
          <button className="btn-piatti" onClick={() => setServizio("cena")}>
            Cena
          </button>
          <button
            className="btn-piatti-2"
            onClick={() => navigate("/griglia-maitreFE")}
          >
            Griglia Maitre FE
          </button>
        </div>
        <div className="mt-2">
          <button className="btn-piatti" onClick={handleAssignTable}>
            Assegna Tavolo
          </button>
        </div>
        <div className="mt-2">
          <label>Prenotazioni:</label>
          <select
            value={selectedReservation}
            onChange={(e) => setSelectedReservation(e.target.value)}
          >
            <option value="">Seleziona una prenotazione</option>
            {ordinePiatti.map((reservation) => (
              <option
                key={reservation.id_prenotazione}
                value={reservation.id_prenotazione}
              >
                {reservation.cognome} -{" "}
                {reservation.numero_tavolo
                  ? reservation.numero_tavolo
                  : "Da assegnare tavolo"}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <label>Tavoli:</label>
          <select
            value={selectedTable}
            onChange={(e) => setSelectedTable(e.target.value)}
          >
            <option value="">Seleziona un tavolo</option>
            {availableTables.map((table) => (
              <option key={table.id_tavolo} value={table.id_tavolo}>
                Tavolo {table.numero_tavolo} - Capacità {table.capacita}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <label>
            <input
              type="checkbox"
              checked={invert}
              onChange={(e) => setInvert(e.target.checked)}
            />
            Inverti righe e colonne
          </label>
        </div>
        <div className="mt-2">
          <h2>Ordini:</h2>
          {renderTable()}
        </div>
      </div>
    </div>
  );
}

export default GrigliaMaitre;
