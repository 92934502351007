import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import DettaglioPiatto from "./pages/DettaglioPiatto";
import GestionePiatto from "./pages/GestionePiatto";
import GestionePrenotazione from "./pages/GestionePrenotazione";
import InserisciPrenotazione from "./pages/InserisciPrenotazione";
import Report from "./pages/Report";
import TuttePrenotazioni from "./pages/TuttePrenotazioni";
import GrigliaChef from "./pages/GrigliaChef";
import MenuPrenotazione from "./pages/MenuPrenotazione";
import CalendarioMenu from "./pages/CalendarioMenu";
import CreaMenu from "./pages/CreaMenu";
import VisualizzaMenu from "./pages/VisualizzaMenu";
import LoginMenu from "./components/LoginMenu";
import CompilaMenu from "./components/CompilaMenu";
import LoginForm from "./components/LoginForm"; // Importa il componente LoginForm
import Impostazioni from "./pages/Impostazioni";
import GrigliaMaitre from "./pages/GrigliaMaitre";
import "./style.css";
import RecuperaPassword from "./components/RecuperaPassword";
import InserisciTavolo from "./pages/InserisciTavolo";
import GrigliaMaitreFE from "./pages/GrigliaMaitreFE";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const hideSidebarPaths = [
    "/login-menu",
    "/compila-menu",
    "/login",
    "/recupera-password",
    "/griglia-maitreFE",
  ]; // Percorsi dove la sidebar non deve apparire
  const showSidebar = !hideSidebarPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <div className="grid grid--full" style={{ height: "100vh" }}>
      {showSidebar && <Sidebar />}
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dettaglio-piatto" element={<DettaglioPiatto />} />
        <Route path="/calendario-menu" element={<CalendarioMenu />} />
        <Route path="/gestione-piatto" element={<GestionePiatto />} />
        <Route path="/crea-menu" element={<CreaMenu />} />
        <Route path="/visualizza-menu" element={<VisualizzaMenu />} />
        <Route
          path="/gestione-prenotazione"
          element={<GestionePrenotazione />}
        />
        <Route
          path="/inserisci-prenotazione"
          element={<InserisciPrenotazione />}
        />
        <Route path="/report" element={<Report />} />
        <Route path="/griglia-chef" element={<GrigliaChef />} />
        <Route path="/griglia-maitre" element={<GrigliaMaitre />} />
        <Route path="/impostazioni" element={<Impostazioni />} />
        <Route path="/tavoli" element={<InserisciTavolo />} />
        <Route path="/griglia-maitreFE" element={<GrigliaMaitreFE />} />
        <Route path="/login-menu" element={<LoginMenu />} />
        <Route path="/recupera-password" element={<RecuperaPassword />} />
        <Route path="/login" element={<LoginForm />} />{" "}
        {/* Aggiungi la route per LoginForm */}
        <Route
          path="/compila-menu/:id_prenotazione"
          element={<CompilaMenu />}
        />
      </Routes>
    </div>
  );
}

export default App;
